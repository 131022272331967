import React, { useContext, useRef, useEffect, useState } from "react";
import { DateTime } from 'luxon';
import { Redirect } from "react-router-dom";
import { getFinalistsOnSnapshot } from '../services/FirestoreService';
import Layout from "../components/Layout";
import Deadline from "../components/Deadline";
import EntriesListVote from '../components/EntriesListVote';
import EntriesList from "../components/EntriesList";
import WinnerLink from "../components/WinnerLink";
import ShareButtons from "../components/ShareButtons";
import Logo from "../assets/images/logo.png";
import { AppContext } from '../services/AppContext';

const VoteText = ({ cutoff }) => {
  const now = DateTime.now().toSeconds();
  if (now < cutoff) {
    return (
      <h2 className="text-slate font-bold text-lg md:text-xl my-4">
        Finals are here. Vote for your favorite!!
      </h2>
    )
    } else {
      return null;
    }
}

const Finalists = () => {
  const [finalistsList, setFinalistsList] = useState([]);
  const linkRef = useRef(null);
  
  const {
    appState,
    contestDetails,
    contestId,
    finalEnabled,
  } = useContext(AppContext);
  
  const { finalVotedFor } = appState;

  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const shareLink = `${window.location.origin}/finalists`;

  useEffect(() => {
    const unsubscribe = getFinalistsOnSnapshot(contestId, (querySnapshot) => {
      var entries = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          entries.push({
            ...doc.data(),
            id
          });
        });
        setFinalistsList(entries);
    });
    return unsubscribe;
  }, [contestId, setFinalistsList]);

  let deadlineHeader = finalEnabled ? 'Voting ends on' : 'Finals starts on';
  if (finalVotedFor) {
    deadlineHeader = 'Winner will be announced on'
  }
  if (!finalEnabled) {
    return <Redirect to="/entries" />
  }

  return (
    <Layout maxSize="md:max-w-screen-xl">
      <div className="text-left">
        <div className="p-4">
          <div className="grid grid-cols-2 md:grid-cols-3 flex justify-between mx-4 my-2">
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="text-slate flex-col flex items-center space-x-3">
              <div className="uppercase text-xl md:text-2xl -mt-1">
                Finals
              </div>
            </div>
            <div className="hidden md:block">
              <Deadline
                headerText={deadlineHeader}
                timestamp={ finalEnabled ? contestDetails.finalEndTimestamp : contestDetails.finalStartTimestamp }
              />
            </div>
          </div>
          
          {/* MOBILE */}
          <div className="grid grid-cols-1 md:hidden">  
            <div className="m-4 flex flex-col justify-around text-center">
              {finalVotedFor ? (
                <>
                  <h2 className="text-slate font-bold text-lg md:text-3xl my-4">
                    Thank you for voting!!
                  </h2>
                  
                </>
              ) : (
                <VoteText cutoff={contestDetails.finalEndTimestamp} />
              )}
              <Deadline
                headerText={deadlineHeader}
                timestamp={ finalEnabled ? contestDetails.finalEndTimestamp : contestDetails.finalStartTimestamp }
              />
              <WinnerLink />
            </div>
          </div>
          
          {/* DESKTOP */}
          <div className="grid grid-cols-3 md:grid-cols-5">
            <div className="m-4 flex flex-col justify-around hidden md:block">
              { finalVotedFor ? (
                <h2 className="text-slate font-bold text-lg md:text-3xl my-4">
                  Thank you for voting!
                </h2>
              ) : (
                <VoteText cutoff={contestDetails.finalEndTimestamp} />
              )}
              <WinnerLink />
            </div>
            { finalEnabled ?
              <EntriesListVote entries={finalistsList} deadline={contestDetails.finalStartTimestamp} />
              :
              <EntriesList entries={finalistsList} />
            }
          </div>
        </div>
        {/* This element needed for the copy to clipboard */}
        <textarea
            type="link"
            ref={linkRef}
            style={{ height: 0, width: 0, opacity: 0, padding: 0 }}
            defaultValue={shareLink}
          >
        </textarea>
        <div className="mx-auto text-center flex justify-center pb-6">
          <div className="flex items-center text-center  space-x-3">
            <a
              href={shareLink}
              target="_blank"
              className="text-coolGrey text-lg"
              rel="noreferrer"
            >
              { shareLink }
            </a>
            <button onClick={copyToClipboard}>
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="#1b3668"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Finalists;
