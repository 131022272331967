import React, { useContext, useRef, useEffect, useState } from "react";
import ModalWrapper from "../components/ModalWrapper";
import Deadline from "../components/Deadline";
import { AppContext } from '../services/AppContext';

const EntriesList = ({ entries, deadline }) => {
  const { appState, contestDetails, voteFinalFor } = useContext(AppContext);
  const { finalVotedFor } = appState;
  const [voted, setVoted] = useState(null);
  const [thanksModal, setThanksModal] = useState(false);  
  const vote = (id) => {
    voteFinalFor(id)
      .then(() => {
        setThanksModal(true);
        setVoted(id);
      })
  };
  useEffect(() => {
    if (entries.length && voted === null) {
      if (finalVotedFor && entries.find(entry => entry.id === finalVotedFor)) {
        setVoted(finalVotedFor);
      }
    }
  }, [entries]);
  
  return (
    <>
    {entries.map((item, index) => {
      const disabled = voted !== null;
      return (
        <div className=" m-4 relative" key={item.id}>
          <img src={item.url} className="relative top-0 z-10" alt="" />
          <div className=" absolute -bottom-4 z-10 text-center w-full">
            <button
              disabled={disabled}
              className={`${
                voted === null || voted === item.id
                  ? "bg-slate"
                  : "bg-gray-500"
              } text-white px-3 py-2 text-sm uppercase mx-auto font-bold inline-block`}
              onClick={() => vote(item.id)}
            >
              {voted === item.id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                </svg>
              ) : (
                `VOTE`
              )}
            </button>
          </div>
        </div>
      );
    })}
    <ModalWrapper
        show={thanksModal}
        close={() => setThanksModal(false)}
        className="max-w-screen-md"
      >
        <div className="text-center  text-coolGrey">
          <div className="text-coolGrey space-y-8 p-6 text-lg">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              Thank you! <br /> The winner will be announced on
            </h2>
            <Deadline
              headerText=""
              timestamp={ contestDetails.finalEndTimestamp }
            />
            <button
              type="submit"
              onClick={() => setThanksModal(false)}
              className={`bg-slate text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
            >
              Close
            </button>
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}

export default EntriesList;