import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../services/AppContext'
import Layout from "../components/Layout";
import ModalWrapper from "../components/ModalWrapper";
import Logo from "../assets/images/logo.png";
import ContestImage from "../components/ContestImage";
import { forEach } from "async";

const SignUpForm = () => {
  const { signUp, signUpError } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  
  const initialField = {value: '', error: false};
  const [nameField, setNameField] = useState(initialField);
  const [emailField, setEmailField] = useState(initialField);
  const [phoneField, setPhoneField] = useState(initialField);
  const [termsField, setTermsField] = useState({value: false, error: false});
  
  const validateFields = () => {
    let valid = true;
    if (nameField.value === '') {
      setNameField({
        value: '',
        error: true,
      });
      valid = false;
    }
    if (emailField.value === '' || !validEmail(emailField.value)) {
      setEmailField({
        value: emailField.value,
        error: true,
      });
      valid = false;
    }
    if (!termsField.value) {
      setTermsField({value: false, error: true});
      valid = false;
    }
    return valid;
  }
  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (validateFields()) {
      signUp({
        name: nameField.value,
        email: emailField.value,
        phone: phoneField.value,
      });
    }
  };
  const validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const termsModal = () => {
    return (
      <ModalWrapper
        show={showModal}
        close={() => setShowModal(false)}
        className="max-w-screen-md"
      >
        <div className="text-center ">
          <div className="text-slate space-y-6 ">
            <div className="flex justify-around items-center">
              <div>
                <img alt="" src={Logo} />
              </div>
              <h2 className="text-slate font-bold text-xl md:text-3xl uppercase">
                Terms and Conditions for the Photo Content
              </h2>
            </div>
            <div className="p-6 border border-gray-200 ">
              <div className="text-coolGrey text-left h-96 overflow-auto">
                <iframe className="terms-frame" src="/terms.html" title="terms" />
              </div>
            </div>

            <div>
              <button
                onClick={() => {
                  setTermsField({
                    value: true,
                    error: false,
                  })
                  setShowModal(false);
                }}
                className={`bg-slate text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
              >
                I agree
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  };

  return (
    <Layout>
      { termsModal() }    
      <div className="grid grid-cols-1 md:grid-cols-2">
        <form>
          <div className="p-6 flex flex-col flex-1 h-full justify-between">
            <div className="text-slate flex justify-between items-end">
              <div>
                <img alt="" src={Logo} />
              </div>
            </div>
            <h2 className="text-slate font-bold text-xl md:text-3xl my-4 uppercase">
              Submit your PHOTO!
            </h2>
            <p className="text-black text-base md:text-lg text-coolGrey pr-6">
              Enter your contact information and accept the terms and conditions
              of the contest to participate. <br /> Our team will review and
              approve the photo so you can share it. Get the votes, get the
              prize!
            </p>
            <div className="pr-6">
              <div className="space-y-6 mt-4">
                <div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={nameField.value}
                    placeholder="NAME"
                    className="form-input w-full"
                    onChange={(ev) => setNameField({
                      value: ev.target.value,
                      error: false,
                    })}
                  />
                  {nameField.error && (
                    <small className="text-red">
                      Name is required
                    </small>
                  )}
                </div>

                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(ev) => setEmailField({
                      value: ev.target.value,
                      error: false,
                    })}
                    value={emailField.value}
                    placeholder="EMAIL"
                    className="form-input w-full"
                  />
                  {emailField.error && (
                    <small className="text-red">
                      A valid email is required
                    </small>
                  )}
                </div>
                <div>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    value={phoneField.value}
                    placeholder="PHONE"
                    onChange={(ev) => setPhoneField({value: ev.target.value, error: false})}
                    className="form-input w-full"
                  />
                </div>
                <div>
                  <label className="text-sm">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      placeholder="PHONE"
                      className="form-input mr-2"
                      value="1"
                      checked={termsField.value}
                      onChange={(ev) =>
                        setTermsField({
                          value: ev.target.checked,
                          error: false,
                        })
                      }
                    />
                    I accept{" "}
                    <button
                      className="text-lightBlue"
                      type="button"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      Terms and Conditions
                    </button>
                  </label>
                  {termsField.error && (
                    <small className="block text-red">
                      Terms are required
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center mt-3">
              <p className="text-red">
                {/* This can be changed for a material Alert */}
                {signUpError}
              </p>
              <button
                type="button"
                disabled={false}
                onClick={(ev) => {
                  handleSubmit(ev);
                }}
                className={`bg-slate text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
              >
                Share my photo
              </button>
            </div>
          </div>
        </form>
        <div className="p-6 flex flex-col justify-between items-center space-y-4 md:space-y-0 bg-lightGray rounded-xl">
          <ContestImage />
        </div>
      </div>
    </Layout>
  );
};

export default SignUpForm;
