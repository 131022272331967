import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Logo from "../assets/images/logo.png"
import Deadline from "../components/Deadline";
import ProcessingEntryModal from "../components/ProcessingEntryModal";
import ShareButtons from "../components/ShareButtons";
import { AppContext } from '../services/AppContext'


const Share = () => {
  const linkRef = useRef(null);
  
  // TBC: Id in URL overrides ID in local storage?
  // Use case: If someone else opens my share link or if I want to open on another browser.
  // If no, it can be impossible to retrieve vote link and URL if local storage is lost
  // const { userId } = useParams();
  const {
    appState,
    contestDetails,
    getAccepted,
   } = useContext(AppContext);
  const {
    userId,
    downloadUrl,
    userName
   } = appState;

  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  };

  const callGetAccepted = useCallback (() => {
    getAccepted();
  },[getAccepted]);

  useEffect(() => {
    if (!downloadUrl) {
      callGetAccepted();
    }
  }, [downloadUrl, callGetAccepted]);

  const voteLink = `${window.location.origin}/vote/${userId}`;

  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col flex-1 h-full justify-between">
          {/* <div className="text-slate flex justify-between items-center"> */}
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="uppercase text-xl md:text-3xl py-4 self-center">
              { userName }
            </div>
          {/* </div> */}
          <div>
            <Deadline headerText="Voting ends on" timestamp={ contestDetails.uploadEndTimestamp } />
          </div>
          <h2 className="text-slate font-bold text-xl md:text-2xl my-4 text-center">
            Your photo is now approved, you can start sharing it to earn points!
          </h2>
          {/* <ShareButtons /> */}
          <h2 className="text-slate font-bold text-xl md:text-2xl my-4  text-center">
            How to earn points:
          </h2>

          <div className="text-slate text-base md:text-lg  pr-6">
            <p>Each time that…</p>
            <ol className="list-decimal ml-5">
              <li>
                Your photo is voted <span className="float-right">1pt</span>
              </li>
              {/* <li>
                Your photo is shared on social media{" "}
                <span className="float-right">5pt</span>
              </li> */}
              <li>
                Your friend adds their email{" "}
                <span className="float-right">10pt</span>
              </li>
            </ol>
            {/* This element needed for the copy to clipboard */}
              <textarea
              type="link"
              ref={linkRef}
              style={{ height: 0, width: 0, opacity: 0, padding: 0 }}
              defaultValue={voteLink}
            >
            </textarea>
          </div>

          <div className="my-4 flex justify-between items-center">
              <a
                href={ voteLink }
                className="p-1 text-sm font-bold"
                rel="noreferrer"
              >
                { voteLink }
              </a>
            <button onClick={copyToClipboard}>
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="#1b3668"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-row justify-start">
            <a
              href={voteLink}
              className={`border-2 border-slate text-slate px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
              rel="noreferrer"
            >
              MY LINK FOR VOTING
            </a>
          </div>
        </div>
        <div className="p-6 bg-lightGray">
          <img src={downloadUrl} className="relative top-0 z-6" alt="" />
        </div>
        <div className="flex justify-between items-center py-6">
          <Link to="/entries"
              className={`border-2 border-slate text-slate px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
            >
              SEE ALL ENTRIES
          </Link>
        </div>
      </div>
      <ProcessingEntryModal show={downloadUrl === null} />
    </Layout>
  );
};

export default Share;
