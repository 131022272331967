import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Cropper from "react-easy-crop";
import Resizer from 'react-image-file-resizer';
import { AppContext } from '../services/AppContext'
import Layout from "../components/Layout";
import getCroppedImg from "../components/CropImage";
import Logo from "../assets/images/logo.png";
import OverlayTop from "../assets/images/image-cover.png";
import UploadIcon from "../components/UploadIcon";
import "../styles/uploadPhoto.css";

const UploadPhoto = () => {
  let history = useHistory();
  const { uploadImage } = useContext(AppContext);

  // Submit Photo
  const handleSubmit = (ev, useModal) => {
    ev.preventDefault();
    uploadImage(croppedImage, uploadSuccess);
  };

  const uploadSuccess = () => {
    setProcessingUpload(false);
  };

  // Probably need to check userId before doing anything because  it can be redirected here
  // from App.js if share/:userId in the URL and no uploadComplete in local storage

  const photoWidth = 375;
  const photoHeight = 499;
  const [processingUpload, setProcessingUpload] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback(

    async (croppedArea, croppedAreaPixels) => {

      try {
        const croppedImage = await getCroppedImg(
          selectedImage,
          croppedAreaPixels,
          OverlayTop,
          photoWidth,
          photoHeight,
        );
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [selectedImage]
  );

  const [showImageCrop, setShowImageCrop] = useState(false);
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        // We don't want to resize that much before cropping, only if it is really huge ( => x8 )
        Resizer.imageFileResizer(
          e.target.files[0],
          photoWidth*5,
          photoHeight*5,
          'JPEG',
          80,
          0,
          async uri => {
            let imageDataUrl = await readFile(uri);
            setSelectedImage(imageDataUrl);
          },
          'blob'
        );
      } catch (err) {
        let imageDataUrl = await readFile(e.target.files[0]);
        setSelectedImage(imageDataUrl);
      } finally {
        setShowImageCrop(true);
      }
    }
  };

  const stepOne = () => {
    return (
      <div className="p-6 flex flex-col flex-1 h-full justify-center items-center">
        <div className="bg-lightGray flex flex-col justify-between" style={{ width: photoWidth, height: photoHeight, position: 'relative' }}>
          <img
            src={OverlayTop}
            className="bg-center overlay"
            alt=""
          />
          <div className="photo-form">
            <form>
              <div className="text-center m-3">
                <label className="mx-auto text-center text-slate cursor-pointer">
                  <UploadIcon />
                  <div className="uppercase text-2xl">Click here to upload</div>
                  <input
                    accept="image/*"
                    type="file"
                    className=" hidden"
                    onChange={onSelectFile}
                  />
                  <p className="text-left m-8">
                  <small>
                    * All photos will be scanned and vetted to fit the terms and
                    conditions.{" "}
                  </small>
                </p>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  const stepTwo = () => {
    return (
      <div className="p-6 flex flex-col flex-1 h-full justify-center items-center">
        {/* <h2 className="text-slate font-bold text-xl md:text-3xl my-4 uppercase pr-6">
          Resize and Crop
        </h2> */}
        <div className="block md:hidden">{showImageCrop ? submitButton() : null}</div>
        <div className="relative" style={{ width: photoWidth, height: photoHeight }}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
            aspect={photoWidth/photoHeight}
            onMediaLoaded={(mediaSize) => {
              // Adapt zoom based on media size to fit max height
              var a = mediaSize.naturalHeight / photoHeight   ; // This may be too much, given the overlay space
              var b = mediaSize.naturalWidth / photoWidth;
              setZoom(Math.max(a,b));
            }}
            maxZoom={10}
            cropSize={{width: photoWidth, height: photoHeight}}
          />
          <img
            src={OverlayTop}
            className="relative overlay bg-center"
            alt=""
          />
        </div>
        <div className="block md:hidden">{showImageCrop ? submitButton() : null}</div>
      </div>
    )
  }

  const submitButton = (showCancel = true) => {
    return (
      <div className="text-center mt-3 space-x-3">
        { showCancel ?
        <button
          type="submit"
          onClick={() => {
            setSelectedImage(null);
            setCroppedImage(null);
            setShowImageCrop(false);
          }}
          className={`bg-white border-slate border text-slate px-3 py-2 text-lg uppercase mx-auto font-bold inline-block mb-5`}
        >
          Cancel
        </button> : null }
        <button
          type="button"
          onClick={(ev) => handleSubmit(ev, showCancel)}
          className={`bg-slate text-white px-3 border-slate border py-2 text-lg uppercase mx-auto font-bold inline-block`}
        >
          Submit Photo
        </button>
      </div>
    )
  }

  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col flex-1 h-full justify-between relative">
          <div className="p-6 bg-lightGray">
            <div className="p-6 flex flex-col flex-1 h-full justify-between">
              <div className="text-slate flex justify-between items-center">
                <div>
                  <img alt="" src={Logo} />
                </div>
              </div>
              <h2 className="text-slate font-bold text-xl md:text-3xl my-4 uppercase pr-6">
                FOLLOW THE INSTRUCTIONS TO UPLOAD YOUR PHOTO
              </h2>
              <div className="text-black text-base md:text-lg text-coolGrey pr-6">
                <ol className="list-decimal ml-5">
                  <li>Click on the Upload Photo icon</li>
                  <li>Select the photo from your library</li>
                  <li>Enlarge, shrink, and move the photo to fit the frame</li>
                  <li>Submit the photo</li>
                  <li>
                    Our team will review and approve the photo so you can share
                    it.
                  </li>
                </ol>
                <p>Get the votes, get the prize!</p>
              </div>
            </div>
            <div className="hidden md:block">{showImageCrop ? submitButton() : null}</div>
          </div>
        </div>
        {showImageCrop ? stepTwo() : stepOne()}
      </div>
    </Layout>

    //   <ModalWrapper
    //     show={errorModal}
    //     close={() => setErrorModal(false)}
    //     className="max-w-screen-md"
    //   >
    //     <div className="text-center  text-coolGrey">
    //       <div className="text-coolGrey space-y-8 p-6 text-lg">
    //         <p>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 24 24"
    //             className="w-20 h-20 mx-auto"
    //             fill="#1b3668"
    //           >
    //             <path d="M0 0h24v24H0z" fill="none" />
    //             <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    //           </svg>
    //         </p>
    //         <h2 className="font-bold text-xl md:text-3xl uppercase">
    //           There was an error with your photo, please try again
    //         </h2>
    //         <button
    //           type="submit"
    //           onClick={(ev) => handleSubmit(ev)}
    //           className={`bg-red text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
    //         >
    //           RE-SUBMIT PHOTO
    //         </button>
    //       </div>
    //     </div>
    //   </ModalWrapper>
    // </Layout>
  );
};

export default UploadPhoto;
