
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SessionWidget from './SessionWidget';
import Footer from "./Footer";
import { AppContext } from '../services/AppContext';

const Layout = ({ children, maxSize }) => {
  
  const { contestDetails } = useContext(AppContext);
  useEffect(() => {
    const bodyEl = document.getElementsByTagName('body');
    bodyEl[0].setAttribute(`style`, `background-image: url("${contestDetails.backgroundImage}")`);
  }, [contestDetails] );
  return (
    <div
      className={`container mx-auto my-10 md:my-32 font-lato px-3 md:px-0 ${
        maxSize ? maxSize : "md:max-w-screen-lg"
      }`}
    >
      <SessionWidget />
      <div className="wrapper bg-lightGray rounded-xl">
        {children}
        <Footer />
      </div>
    </div>
  );
    };

export default Layout;
