import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import firebase from '../firebase.config';
import { saveImageToBucket } from './StorageService';
import { getActiveContest, saveVoterEmailName } from './FirestoreService';
import { appConfig } from '../App.config';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [appState, setAppState] = useState(null);
  const [hydrated, setHydrated] = useState(false);
  const [signUpError, setSignUpError] = useState('');
  const [contestId, setContestId ] = useState('');
  const [intervalAccepted, setIntervalAccepted] = useState(null);
  const [contestDetails, setContestDetails] = useState({
    name: '',
    description: '',
    instructions: '',
    awardImage: '',
    uploadEndTimestamp: null,
    finalStartTimestamp: null,
    finalEndTimestamp: null,
  });
  const [voteEnabled, setVoteEnabled] = useState(null);
  const [finalEnabled, setFinalEnabled] = useState(null);
  const [winnerAnnounced, setWinnerAnnounced] = useState(false);
  
  useEffect(() => {
    if (hydrated) {
      return;
    }
    let initialState = {
      userEmail: null,
      userName: null,
      userId: null,
      downloadUrl: null,
      uploadComplete: null,
      voteComplete: null,
      voteLevel: null,
      voteFinalComplete: null,
      finalVotedFor: null,
    };
    getActiveContest()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // should be always one
          setContestId(doc.id);
          const {
            name,
            description,
            instructions,
            awardImage,
            finalStartTimestamp,
            uploadEndTimestamp,
            finalEndTimestamp,
            winnerAnnounced,
            backgroundImage,
          } = doc.data();
          setContestDetails({
            name,
            description,
            instructions,
            awardImage,
            uploadEndTimestamp,
            finalStartTimestamp,
            finalEndTimestamp,
            winnerAnnounced,
            backgroundImage,
          });
          initialState.contestId = doc.id;
          const now = Math.floor(Date.now() / 1000);
          setVoteEnabled(now < uploadEndTimestamp);
          setFinalEnabled(now > finalStartTimestamp && now < finalEndTimestamp);
          setWinnerAnnounced(winnerAnnounced || false);
          const localObj = localStorage.getItem('appState');
          const savedSession = JSON.parse(localObj);
          if (savedSession === null ||
            !savedSession.contestId ||
            savedSession.contestId !== doc.id) {
            updateState(initialState);
          } else {
            updateState(savedSession);
          }
          setHydrated(true);
        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }, [hydrated]);

  const history = useHistory();

  const updateState = (newState) => {
    setAppState(newState);
    localStorage.setItem('appState', JSON.stringify(newState));
  };

  const saveVoter = payload => {
    return saveVoterEmailName({
      ...payload,
      contestId,
    });
  };

  const signUp = payload => {
    const now = DateTime.now().toSeconds();
    const entriesRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('entries')
      
      
      entriesRef.where('email', '==', payload.email)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const errorMsg = 'Cannot Duplicate entry (email already participating)';
            throw new Error(errorMsg);
          }
          return entriesRef.add({
            ...payload,
            createdTimestamp: Math.round(now),
            updatedTimestamp: Math.round(now),
          });
        })
        .then((docRef) => {
          updateState({
            ...appState,
            userEmail: payload.email,
            userName: payload.name,
            userId: docRef.id,
          });
          history.push('/upload');
        })
        .catch((error) => {
          setSignUpError(error.message);
        });
  };

  const clearSession = () => {
    localStorage.clear();
    setAppState(null);
    history.push('/');
  };

  const clearContestId = () => {
    const newState = {
      ...appState,
    };
    delete newState.contestId;
    localStorage.setItem('appState', JSON.stringify(newState));
    setAppState(newState);
  };
  
  const saveUploadComplete = () => {
    updateState({
      ...appState,
      uploadComplete: true,
    });
  };

  const saveUploadUrl = (downloadUrl) => {
    updateState({
      ...appState,
      downloadUrl: downloadUrl,
    });
  };

  const getEntry = (entryId) => {
    const acceptedRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('accepted')
      .doc(`${entryId}`);
    return acceptedRef.get()
      .then((doc => {
        return doc.data();
      }))
  };
  const voteFor = (entryId) => {
    const now = DateTime.now().toSeconds();
    const { voteLevel } = appState;
    const newLevel = voteLevel === 1 ? 10 : 1
    const acceptedRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('accepted')
      .doc(`${entryId}`);

    return acceptedRef.update({
        votes: firebase.firestore.FieldValue.increment(newLevel),
        updatedTimestamp: Math.round(now),
      })
      .then(()=> {
        updateState({
          ...appState,
          voteComplete: true,
          voteLevel: newLevel,
        });
      });
  };

  const voteFinalFor = (entryId) => {
    const now = DateTime.now().toSeconds();
    const { voteLevel } = appState;
    const newLevel = voteLevel === 1 ? 10 : 1
    const acceptedRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('finalists')
      .doc(`${entryId}`);

    return acceptedRef.update({
        votes: firebase.firestore.FieldValue.increment(newLevel),
        updatedTimestamp: Math.round(now),
      })
      .then(()=> {
        updateState({
          ...appState,
          voteFinalComplete: true,
          finalVotedFor: entryId,
        });
      });
  };

  const getAccepted = () => {
    if (intervalAccepted) {
      // Don't run twice
      return;
    }
    const { userId } = appState;
    const acceptedRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('accepted')
      .doc(`${userId}`);

    const checkAccepted = () => {
      acceptedRef.get().then((doc) => {
        if (doc.exists) {
          const { url } = doc.data();
          // success(doc.data());
          saveUploadUrl(url);
          clearInterval(intervalAccepted);
          setIntervalAccepted(null);
        } else {
          // doc.data() will be undefined in this case
          console.log('Not accepted yet');
        }
      }).catch((error) => {
          console.log('Error getting document:', error);
      });
    }
    setIntervalAccepted(setInterval(checkAccepted, 2000));
  };

  const getWinner = () => {
    const winnerRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('winner')
    let winner = [];
    return winnerRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          winner.push(doc.data());
        });
        return winner;
      });
  };

  const saveAcceptedTmp = (url) => {
    const now = DateTime.now().toSeconds();
    const acceptedRef = firebase.firestore()
      .collection('tenants')
      .doc(`${appConfig.tenant}`)
      .collection('contests')
      .doc(`${contestId}`)
      .collection('accepted')
      .doc(appState.userId);
    acceptedRef.set({
      name: appState.userName,
      votes: 0,
      url,
      createdTimestamp: Math.round(now),
      updatedTimestamp: Math.round(now),
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const uploadImage = (file, success) => {
    const onUploadImageSuccess = (url) => {
      saveUploadComplete();
      saveAcceptedTmp(url);
      success();
    }
    saveImageToBucket(file, contestId, appState.userId, onUploadImageSuccess);
  };

  return (
    <AppContext.Provider
      value={{
        contestDetails,
        contestId,
        saveVoter,
        signUp,
        signUpError,
        clearContestId,
        clearSession,
        uploadImage,
        voteFor,
        voteFinalFor,
        getAccepted,
        getEntry,
        getWinner,
        appState,
        voteEnabled,
        finalEnabled,
        winnerAnnounced,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
