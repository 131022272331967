import React, { useContext } from "react";
import { AppContext } from '../services/AppContext';

const ContestImage = (eventId) => {

  const { contestDetails } = useContext(AppContext);
  
  return (
    <img src={contestDetails.awardImage} alt="Welcome"
      className="shadow-xl border-solid border-8 border-white" />
  );
}

export default ContestImage;