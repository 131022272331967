import firebase from "../firebase.config";
import { appConfig } from '../App.config';

export const saveImageToBucket = (file, contestId, userId, success) => {

  // Create a root reference
  var storageRef = firebase.storage().ref();
  // Create the file metadata
  var metadata = {
    contentType: 'image/jpeg'
  };
  
  // Upload file and metadata to the object kisscam/${contestId}/${userId}.jpg
  var uploadTask = storageRef.child(`${appConfig.tenant}/${contestId}/${userId}.jpg`).put(file, metadata);
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
        default:
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          console.error(`User doesn't have permission to access the object`);
          break;
        case 'storage/canceled':
          console.error(`// User canceled the upload`);
          break;

        // ...

        case 'storage/unknown':
          console.error(`Unknown error occurred, inspect error.serverResponse`);
          break;
        default:
          console.error(`DEFAULT Unknown error occurred, inspect error.serverResponse`);
          break;
      }
    }, 
    () => {
      // Upload completed successfully
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        // TMP
        success(downloadURL);
      });
    })
}

