const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => {
      reject(error);
    });
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {File} overlaySrc - Overlay Image
 */
  export default async function getCroppedImg(imageSrc, pixelCrop, overlaySrc, w, h) {

  const image = await createImage(imageSrc);
  const overlay = await createImage(overlaySrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = w;
  canvas.height = h;

  // translate canvas context to a central location on image to allow rotating around the center.
  // ctx.translate(safeArea / 2, safeArea / 2);
  // ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  // https://developer.mozilla.org/es/docs/Web/API/CanvasRenderingContext2D/drawImage
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    w,
    h,
  );
  ctx.drawImage(
    overlay,
    0,
    0,
    w,
    h,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = w;
  canvas.height = h;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0,
    0
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(new File([blob], "fileName.jpg", { type: "image/jpeg" }))
    }, "image/jpeg");
  });
}
